import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@base/buttons/Button'
import SearchIcon from '@iconscout/unicons/svg/line/search.svg'

type Props = {
  isUploading: boolean
}

const SubmitButton: FC<Props> = ({ isUploading }) => {
  const { t } = useTranslation('Search form')

  return (
    <Button disabled={isUploading} htmlType="submit" type="primary">
      <SearchIcon />
      {t('submitBtn')}
    </Button>
  )
}

export default memo(SubmitButton)
